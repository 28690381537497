.main {
  background-color: $main-background;
  min-height: 100vh;
  min-width: 100%;

  &-header {
    background-color: white;
    display: flex;
    box-shadow: $header-shadow;
    position: relative;
  }

  &-logo {
    padding: 15px;
  }

  &-content {
    @media (max-width: 768px) {
      padding: 0em;
      margin-top: 0em;
    }
  }
}

.sign-out {
  margin-top: 0;
  margin-bottom: 0;

  &-item {
    width: max-content;
    height: 100%;
    margin-bottom: 0 !important;
    background-color: $button-color-primary;
    align-items: center;

    &-no-bg {
      height: 100%;
      margin-bottom: 0 !important;
      align-items: center;
      @media (min-width: 500px) {
        width: 200px;
      }
    }

    &-link {
      text-decoration: none;
      color: $text-color-secondary;

      &:hover {
        color: $text-color-secondary-hover-dark;
        cursor: pointer;
      }
    }
    &-text {
      color: white;
      margin-bottom: 0 !important;
    }
  }
}

.user-wrapper {
  padding: 3px;

  & p:first-child {
    margin-bottom: 0;
  }
}

/* Menu */
.navbar-expand .navbar-nav .nav-link {
  padding-left: 0;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: flex-end;
    padding-right: 12px;
  }
}

.menu-wrapper {
  padding: 3px;
}

.menu-wrapper .navbar-nav .nav-link,
.mobile-menu .nav-link {
  color: $button-color-primary;
}

.mobile-menu .dropdown-menu {
  border: none;
  padding: 0.25rem 0;
  margin-left: 1em;
}

.custom-nav-link {
  &:hover {
    color: $text-color-primary-hover !important;
  }

  &.active {
    font-weight: bold;
  }
}

/* Dropdown */
.navbar-dropdown .dropdown-menu {
  background-color: transparent;
  border: none;
  padding: 0 0 0 1em;
}

.dropdown-item {
  color: $button-color-primary;
  padding: 0.25rem 0 0.25rem 0;

  &:hover {
    color: $button-color-primary;
  }
}

.dropdown-menu-open {
  margin-bottom: 125px;
}

.navbar-dropdown .dropdown-toggle.btn-primary {
  background-color: transparent;
  border: none;
  filter: none;
  color: $button-color-primary;
  padding: 0.5rem 0 0.5rem 0;
  transition: none;
}

.navbar-dropdown .dropdown-item.active,
.navbar-dropdown .dropdown-item:active {
  background-color: transparent;
  color: $button-color-primary;
}

.navbar-dropdown .dropdown-item:focus,
.navbar-dropdown .dropdown-item:hover {
  background-color: transparent;
}
