.news {
    &-card {
        width: fit-content;
        background-color: $main-background;
        border-radius: 10px;
        padding: 10px;
    }

    &-divider {
      margin-top: 13px;
    }

    &-card-content {
        background-color: white;
        padding: 20px;
    }
  
    // News Header Styling
    &-header {
        padding: 5px;
        margin-top: 0px;
    }
  
    // News Content Styling
    &-content {
      overflow-y: scroll;
      width: 100%;
      height: 70vh;
    }
  
    // News Image Styling
    &-image {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  
    // Tags Section Styling
    &-tags {
      gap: 0.5rem;
    }

    &-info {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 11px;
        margin-bottom: 0.5rem;
    }
  
    // Title Styling
    &-title {
      font-size: 1rem;
      font-weight: bold;
      color: $text-color-dark;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
    }

    // Content Description Styling
    &-description {
      font-size: 13px;
      color: $text-color-dark;
    }
  
    // Admin Delete Button Styling
    &-delete-btn {
      border-radius: 20px;
      position: relative;
      background-color: transparent;
      color: #e74c3c;;
      border: none;
      display: flex;
      justify-content: flex-end;
      padding: 0 8px;
      svg {
        height: 20px;
      }
  
      &:hover {
        span {
          visibility: visible;
        }
      }
  
      span {
        visibility: hidden;
        background-color: white;
        color: black;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        font-size: 14px;
        padding: 0 8px;
        right: 25px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
  
      &.delete {
        color: #e74c3c;
      }
    }
  }
  