.admin {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  @media (min-width: 1440px) {
    padding: 80px;
  }

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  &-form {
    margin-top: 30px;
    max-width: 380px;
    &-group {
      @media (min-width: 500px) {
        display: flex;
        justify-content: space-between;
        .admin-form-input {
          width: 185px;
        }
      }
    }

    &-input {
      width: 380px;

      @media (max-width: 520px) {
        width: auto;
      }
      label {
        font-size: 15px;
        margin-bottom: 0;
      }
      input,
      select {
        border-radius: 8px;
        border: 1px solid $gray-background;
        height: 30px;
        font-size: 15px;
        &:disabled {
          background-color: $main-background !important;
        }
      }
    }

    &-btn {
      height: 30px;
      font-size: 16px;
      padding: 0 10px 0 10px;
      border-radius: 8px;
      margin-top: 8px;
    }
  }

  &-users-table {
    & tbody {
      background-color: white !important;
      font-size: 14px;

      td {
        align-content: center;
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }

    & thead th {
      background-color: $main-background;
      border: none;
      font-size: 16px;
      font-weight: $font-weight-light;
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }

      div {
        &:has(div):hover {
          cursor: pointer;
        }
      }
    }

    &-btn {
      border-radius: 20px;
      float: right;
      position: relative;
      background-color: transparent;
      color: $text-color-primary;
      border: none;
      display: flex;
      justify-content: flex-end;
      padding: 0 8px 0 8px;
      svg {
        height: 20px;
      }

      &-delete {
        color: $text-color-secondary;
      }

      &:hover {
        span {
          visibility: visible;
        }
      }
      span {
        visibility: hidden;
        background-color: white;
        color: black;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        font-size: 14px;
        padding: 0 8px;
        right: 25px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }
  }
}
