@import '../abstracts/variables';

.error-message {
  color: $error-color;
}

.input-error {
  border-color: $error-color;
  border-radius: 5px;
}

.error-size {
  min-height: 25px;
}

.required-star {
  font-size: 12px;
  color: $error-color;
  margin-left: 3px;
}
