.toast {
  &-container {
    height: 200px;
    display: flex;
    justify-content: center;
  }

  &-b-right {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    width: auto;
  }

  &-background {
    background-color: white;
  }

  &-success {
    background-color: white;
  }

  &-error {
    background-color: $error-color;
    color: white;
  }
}
