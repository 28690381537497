.pagination {
  place-content: center;
  .page-item {
    align-content: center;
    padding: 0 2px 0 2px;
    .page-link {
      background-color: transparent;
      border: transparent;
      border-radius: 30px;
      padding: 2px 8px 2px 8px;
      color: $text-color-primary;
      font-size: 14px;

      &:hover {
        background-color: $text-color-primary-hover;
        border-color: $text-color-primary-hover;
        color: white;
      }
    }

    &.active {
      .page-link {
        background-color: $text-color-primary;
        border-color: $text-color-primary;
        color: white;
        align-content: center;
      }
    }
  }

  .page-item:first-child .page-link {
    font-size: 30px;
    padding-bottom: 5px;

    &:hover {
      background-color: transparent;
      border: none;
    }
  }

  .page-item:last-child .page-link {
    font-size: 30px;
    padding-bottom: 5px;

    &:hover {
      background-color: transparent;
      border: none;
    }
  }
}
