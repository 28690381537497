/* Changelog Container */
.changelog-container {
    margin-top: 1rem;
    color: $text-color-dark; /* Light gray text for better readability */
  }

  .changelog-div {
    display: flex;
  }
  
  /* Changelog Item */
  .changelog-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 0;
  }
  
  /* Metadata Tags */
  .changelog-metadata {
    display: flex;
    gap: 8px; /* Space between tags */
    align-items: center;
    flex-wrap: wrap;
  }
  
  /* Changelog Description */
  .changelog-description {
    font-size: 14px;
    margin: 0;
    line-height: 1.5;
    color: $text-color-dark; /* Light gray text */
  }
  