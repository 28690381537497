.custom-spinner {
  color: $text-color-primary;
  width: 2.085rem;
  height: 2.085rem;

  &-small-modal {
    color: $text-color-primary;
    margin-left: 79px;
  }
}

.data-loading-spinner {
  color: $text-color-primary;
  height: 50px;
  width: 50px;
  border-width: 6px;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Or whatever height you want */
  }
}
