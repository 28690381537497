.error-field {
  border-color: $error-color;
}

.error-text {
  color: $error-color;
  font-size: 12px;

  &-align-left {
    text-align: left;
  }
}

.error-container {
  height: 15px;

  &-login {
    height: 7px;
  }

  &-settings {
    height: 15px;
    align-items: center;
    display: flex;
    margin-top: 9px;
  }
}
