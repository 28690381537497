.custom-tag {
    display: inline-block;
    color: #FFFF; 
    font-size: 10px;
    border-radius: 7px;
    padding: 4px 12px;
    line-height: 1.2;
    text-align: center; 
  }

  .tag-remove {
    cursor: pointer;
    margin-left: 5px;
    font-size: 12px;
  }
  

  .tag-remove {
    color: $text-color-dark;
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
    line-height: 1;
  }