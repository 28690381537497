.sync-overview {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  @media (min-width: 1440px) {
    padding: 80px;
  }

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  &-search {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  &-table {
    & tbody {
      background-color: white !important;
      font-size: 14px;

      td {
        align-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    & thead th {
      background-color: $main-background;
      border: none;
      font-size: 16px;
      font-weight: $font-weight-light;
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }

      div {
        &:has(div):hover {
          cursor: pointer;
        }
      }
    }
    &-header {
      display: flex;

      &-action {
        width: 80px;
      }

      svg {
        height: 15px;
        margin-left: 5px;
      }
    }

    &-btn {
      border-radius: 20px;
      display: flex;
      width: 45px;
      height: 35px;
      svg {
        height: 20px;
      }

      .spinner-border {
        height: 20px;
        width: 20px;
      }

      &:hover {
        span {
          visibility: visible;
        }
      }
      span {
        visibility: hidden;
        background-color: white;
        color: black;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        width: 100px;
        right: 105%;
        font-size: 14px;
      }
    }

    &-success {
      background-color: $status-success !important;
      color: $status-success-text;
      font-size: 12px;
    }
    &-failed {
      background-color: $status-failed !important;
      color: $status-failed-text;
      font-size: 12px;
    }
    &-warning {
      background-color: $status-warning !important;
      color: $status-warning-text;
      font-size: 12px;
    }
  }

  &-footer {
    font-size: 14px;

    &-dropdown {
      text-align: right;
      text-align: -webkit-right;
      select {
        width: 70px;
        border-color: $gray-background;
        border-radius: 20px;
        padding: 5px 0 5px 10px;
      }
    }
  }
}
