.modal-backdrop {
  background-color: transparent;
}

/* Accordion */

.accordion-button {
  &:not(.collapsed) {
    background-color: transparent;
    color: $text-color-dark;
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}
