.logo {
  max-width: 50%;
  margin-top: 4em;
  margin-bottom: 4em;
}

.reset-password {
  float: right;
}

.error-col {
  min-height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.center-content-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
