.custom-tab .nav-link.active {
  background-color: $button-color-primary !important;
  color: white;
}

.custom-tab .nav-link {
  background-color: $table-border;
  color: $text-color-dark;
}

.custom-tab .nav-link:hover {
  background: $light-gray-background;
}

.line-tabs {
  .nav-tabs {
    display: flex;
    overflow: hidden;
    margin-bottom: 20px;
    .nav-item {
      flex: 1 1 0;
      overflow: hidden;
      box-sizing: border-box;
      display: block;
      min-width: 0;
      max-width: fit-content;
    }
    .nav-link {
      color: $text-color-primary;
      font-size: 16px;
      padding-bottom: 4px;
      display: block;
      box-sizing: border-box;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .nav-link:hover,
    .nav-link:focus,
    .nav-link:not(:hover) {
      border-bottom: 1px;
      border-top: none;
      border-left: none;
      border-right: none;
    }

    .nav-link:hover {
      color: $text-color-primary-hover;
    }
    .nav-item.show .nav-link,
    .nav-link.active {
      border-bottom: 2px solid $text-color-primary;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}
