.dashboard {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  @media (min-width: 1440px) {
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 15px;
  }

  &-main {
    align-items: center;
    text-align: center;

    &-title {
      font-size: 16px;
      font-weight: bold;
    }

    &-image {
      justify-content: end;
      margin-bottom: 50px;

      img {
        width: 300px;
      }
    }
  }

  &-custom-button {
    max-width: 970px;
    text-align: center;
  
    &-header {
      font-size: large;
      font-weight: $font-weight-bold;
    }
  
    &-container {
      background-color: $main-background;
      border-radius: 10px;
    }
  }
}

.dashboard-welcome {
  padding-left: 4px;
}

.dashboard-buttons-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.dashboard-stats-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

