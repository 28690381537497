.btn-primary {
  background-color: $button-color-primary;
  border-color: $button-color-primary;

  &:hover {
    background-color: $button-color-primary;
    border-color: $button-color-primary;
    filter: $button-shadow-primary;
    transition: 0.3s;
  }

  &.disabled,
  &:disabled {
    background-color: $button-color-primary;
    border-color: $button-color-primary;
    opacity: 0.5;
  }

  &:focus {
    background-color: $button-color-primary;
    border-color: $button-color-primary;
    filter: $button-shadow-primary;
    transition: 0.3s;
  }

  &.active,
  &:active,
  &:first-child:active {
    background-color: $button-color-primary;
    border-color: $button-color-primary;
    filter: $button-shadow-primary;
    transition: 0.3s;
  }

  &.active:focus,
  &:active:focus {
    box-shadow: none;
  }
}

/*  Custom buttons */

.custom-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  color: $text-color-dark !important;
  text-decoration: none !important;
  box-shadow: $button-shadow-secondary;
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  }

  &-header {
    font-weight: $font-weight-bold;
    margin-top: 5px;
    margin-bottom: 5px;
    color: $button-color-primary;
  }

  &-image {
    fill: $button-color-primary;
    width: 50px;
    height: 60px;
    filter: invert(28%) sepia(38%) saturate(213%) hue-rotate(111deg) brightness(92%) contrast(94%);
  }

  &-description {
    font-size: small;
    margin: 5px;
  }
}

.stats-button {
  height: fit-content;
  height: 100%;

  &:hover {
    box-shadow: $button-shadow-secondary;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  z-index: 1000;

  svg {
    height: 25px;
  }

  &:active {
    background-color: $text-color-primary !important;
  }
}

.copy-btn {
  background-color: transparent;
  color: $text-color-primary;
  border: none;

  &:hover,
  &.hover {
    background-color: transparent !important;
    border: none !important;
    color: $text-color-primary-hover;
    filter: none;

    span {
      visibility: visible;
      opacity: 1;
      transition:
        visibility 0s linear 1s,
        opacity 1s ease-in 0.5s;
    }
  }

  &:disabled {
    background-color: transparent !important;
    border: none !important;
    color: $text-color-primary-hover;
    opacity: 1;
    span {
      visibility: visible;
      opacity: 1;
      transition: none;
    }
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    background-color: transparent !important;
    border: none !important;
  }

  span {
    visibility: hidden;
    opacity: 0;
    background-color: white;
    color: black;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 55px;
    font-size: 14px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-align: center;
  }
}
