.account {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  @media (min-width: 1440px) {
    padding: 80px;
  }

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  &-content {
    margin: 50px 0 0 10px;

    p.success {
        svg {
            color: green
        }
    }
    p.failed {
        svg {
            color: $error-color
        }
    }
  }
}
