.search {
  &-input {
    border-radius: 20px !important;
    border-color: $gray-background;
    padding-left: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: -30px;
    margin-right: -30px;
    &::-webkit-search-cancel-button {
      display: none;
    }

    &:focus {
      border-color: $text-color-primary-hover;
      box-shadow: none;
    }
  }

  &-cancel {
    z-index: 1000 !important;
    color: $text-color-primary;
    padding-left: 0;
    padding-right: 24px;

    &:active, &:hover {
      color: $text-color-primary-hover;
    }
  }

  &-icon {
    z-index: 1000;
    align-content: center;
    color: $text-color-primary;
    padding-left: 24px;
  }
}
