.settings {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  @media (min-width: 1440px) {
    padding: 80px;
  }

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2000;
    margin-top: 30px;
    padding: 2px 10px 20px 10px;
  }

  .draggable {
    border: 1px solid $table-border;
    padding: 10px;

    &-item {
      padding: 10px;
      margin: 5px;
      background-color: $table-border;
      cursor: move;
    }
  }

  .settings-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 20px;
  }

  .settings-tab {
    @media (min-width: 768px) {
      position: sticky;
      z-index: 2000;
      top: 160.55px;
      background-color: white;
    }
    .nav-link {
      color: $text-color-dark;

      &.active {
        background-color: $text-color-primary !important;
        color: white;
      }

      &:hover {
        background: $light-gray-background;
      }
    }

    &-error {
      color: $error-color;
    }
  }

  .settings-save-btn {
    display: flex;
    align-items: end;
    justify-content: end;
  }

  .custom-z-index {
    z-index: 1000;
    position: relative;
  }

  .out-eu-currency {
    z-index: 999;
    position: relative;
  }

  .react-select__option--is-selected {
    background-color: $text-color-dark !important;
    color: white !important;
  }

  .react-select__option--is-focused {
    border: none;
    background-color: $text-color-primary-hover !important;
    color: white !important;
  }
}
