.setup {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  @media (min-width: 1440px) {
    padding: 80px;
  }

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }
}

.anchor-selected {
  color: $text-color-primary !important;
  font-weight: 600;
  border-bottom: 1px solid $text-color-secondary;
}

.content-section {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 1rem;
  margin-bottom: 30px;

  &-container {
    padding: 10px;

    &-header {
      font-weight: bold;
      margin-bottom: 30px;
      margin-top: 15px;
      color: $text-color-primary;
    }

    &-image {
      width: 80%;
      box-shadow: $image-shadow;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &:not(:first-child)::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 1px;
    margin-left: 17px;
    opacity: 50%;
    background-color: $text-color-secondary;
    top: 0;
    left: 25%;
  }

  &:before {
    top: 0;
    left: 0;
  }
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #000000a6;
}

.nav-margin {
  margin-top: 10px;
}

.toc-col {
  width: fit-content;
  position: relative;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
  }
}
