.connection {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  @media (min-width: 1440px) {
    padding: 80px;
  }

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  &-card {
    border: 1px solid $gray-background;
    @media (min-width: 590px) {
      padding: 20px;
    }

    @media (max-width: 1240px) {
      flex-direction: column !important;
    }

    &-economic {
      .card {
        border: none;
      }
    }

    &-header {
      padding: 20px 0 0 20px;
      font-size: 17px;
      margin: 0 0 20px 0;
    }

    &-form {
      padding: 20px;
      &-input {
        margin-bottom: 23px;
        width: 380px;

        @media (max-width: 520px) {
          width: auto;
        }
        label {
          font-size: 15px;
          margin-bottom: 0;
        }
        input {
          border-radius: 8px;
          border: 1px solid $gray-background;
          height: 30px;
          font-size: 15px;
          &:disabled {
            background-color: $main-background !important;
          }
        }
      }

      &-btn {
        width: 60px;
        height: 30px;
        font-size: 16px;
        padding: 0;
        border-radius: 8px;
        margin-top: 8px;
      }

      &-error {
        display: flex;
        flex-direction: column;
        width: 380px;

        @media (max-width: 520px) {
          width: auto;
        }
        background-color: #f3f2ef;
        padding: 10px;
        border-radius: 8px;

        pre {
          background-color: transparent !important;
          margin-bottom: 0;
        }

        button {
          text-align: right;
        }
      }

      pre {
        &.success {
          background-color: $status-success;
          border: 1px solid $status-success-text;
          color: $status-success-text;
          width: 380px;

          @media (max-width: 520px) {
            width: auto;
          }
          .string,
          .number,
          .boolean,
          .null,
          .key {
            color: $status-success-text;
          }
        }
        &.failed {
          background-color: $status-failed;
          border: 1px solid $status-failed-text;
          color: $status-failed-text;
          width: 380px;

          @media (max-width: 520px) {
            width: auto;
          }
          .string,
          .number,
          .boolean,
          .null,
          .key {
            color: $status-failed-text;
          }
        }
        border-radius: 8px;
        padding: 6px 15px 6px 15px;
        white-space: pre-wrap;
        width: fit-content;
        max-width: -webkit-fill-available;
        span {
          font-size: 13px;
        }
      }
    }

    &-data {
      width: 420px;
      margin: 20px;
      background-color: $main-background;
      padding: 20px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      @media (max-width: 560px) {
        width: auto;
      }

      pre {
        margin-bottom: 0;
      }

      button {
        text-align: right;
        margin-top: -25px;
      }
    }
  }
}

/* Code format */

pre {
  background-color: #f8f9fa;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}
