.enhanced-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  
  & th,
  & td {
    padding: 15px;
    border: 1px solid $table-border;
    text-align: left;
  }
  
  & thead th {
    background-color: $light-gray-background;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.table-loader {
  text-align: center !important;
}

.table-button {
  margin-right: 10px;
}
