@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import '../abstracts/variables';

html,
body {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  font-family: 'Open Sans', sans-serif;
}

a {
  color: $text-color-secondary;
  text-decoration: none;

  &:hover {
    color: $text-color-secondary-hover;
    text-decoration: none;
  }
}
