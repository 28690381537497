$text-color-primary: #3a4e48;
$text-color-primary-hover: #847f7f;
$text-color-secondary: #ec6c5d;
$text-color-secondary-hover: #f7897d;
$text-color-secondary-hover-dark: #b0594f;
$text-color-dark: #212529;

$font-weight-light: 200;
$font-weight-bold: 600;

$error-color: #ff0000;

$status-success: #E1FBE0;
$status-success-text: #5EA64C;
$status-failed: #FBE0E0;
$status-failed-text: #A64C4C;
$status-warning: #FBF5E0;
$status-warning-text: #DDAC0D;

$button-color-primary: #3a4e48;

$button-shadow-primary: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
$button-shadow-secondary: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
$header-shadow:
  0 1.6px 3.6px rgba(0, 0, 0, 0.132),
  0 0.3px 0.9px rgba(0, 0, 0, 0.108);
$image-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
$table-border: #ddd;

$light-gray-background: #f2f2f2;
$gray-background: #BFBFBD;

$main-background: #f3f2ef;
